@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    @variants hover, focus {
        .filter-blur {
            filter: blur(1px);
        }
    }
}

html, body {
  height: 100%;
}

#root {
  height: 100%;
}